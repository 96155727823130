import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Box } from '@material-ui/core';
import { css } from '@emotion/core';
import { Globals } from '../styles/global';

export const outer = css`
  width: 100%;
  padding: 4rem 0;
  display: flex;
  justify-content: center;
`;

export const CenterBox = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const prepID = (title = 'foo bar'): string =>
  title
    .toLowerCase()
    .split(' ')
    .join('-');

export const ImgResponsive = styled.img`
  width: 100%;
  height: auto;
  display: block;
  max-width: 100%;
`;

export const SwitchBox = styled(Box)`
  ${outer};
`;

export const RedBox = styled.div`
  ${outer};
  background-color: ${Globals.red};
  color: ${Globals.white};
`;

export const OrangeBox = styled.div`
  ${outer};
  background-color: ${Globals.orange};
  color: ${Globals.white};
  .white-link {
    color: ${Globals.white};
    &:hover {
      color: ${Globals.grayscale_d3};
    }
  }
`;

export const DarkGreyBox = styled.div`
  ${outer};
  background-color: ${Globals.grayscale_b};
  color: ${Globals.white};
`;

export const DarkGreyNoYBox = styled.div`
  ${outer};
  background-color: ${Globals.grayscale_b};
  color: ${Globals.white};
  padding-top: 0;
  padding-bottom: 0;
`;

export const GreyBox = styled.div`
  ${outer};
  background-color: ${Globals.grayscale_d2};
`;

export const BlueBox = styled.div`
  ${outer};
  background-color: ${Globals.blue};
`;

export const WhiteBox = styled.div`
  ${outer};
  background-color: ${Globals.white};
  --link-color: ${Globals.grayscale_u2};
  --link-hover-color: ${Globals.grayscale_u1};
`;

export const WhiteBoxGreyText = styled.div`
  ${outer};
  background-color: ${Globals.white};
  --link-color: ${Globals.grayscale_b};
  color: ${Globals.grayscale_b};
  .grey-link {
    color: ${Globals.grayscale_b};
    &:hover {
      color: ${Globals.grayscale_u1};
    }
  }
`;

export const TransparentBox = styled.div`
  ${outer};
  background-color: transparent;
`;

export const PureWhiteBox = styled.div`
  ${outer};
  background-color: ${Globals.pureWhite};
  --link-color: ${Globals.grayscale_u2};
  --link-hover-color: ${Globals.grayscale_u1};
`;

export const PureWhiteBoxNoBottom = styled.div`
  ${outer};
  padding-bottom: 0;
  background-color: ${Globals.pureWhite};
  --link-color: ${Globals.grayscale_u2};
  --link-hover-color: ${Globals.grayscale_u1};
`;

export const Outer = styled.div`
  ${outer}
`;

export const Inner = styled(Box)`
  max-width: 75rem;
  padding: 0 2rem;
  width: 100%;
`;

export const Lay = css`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const QuestionSection = styled.section<{
  bgColor: string;
  color: string;
  linkColor: string;
}>`
  ${Lay};
  ${props => `
    background-color: ${props.bgColor};
    color: ${props.color};
    a {
      color: ${props.color};
      text-decoration: none;
      box-shadow: 0 0.4rem 0 ${props.linkColor};
    }
  `}
  align-items: center;
  min-height: 20rem;
`;

export const FaqBox = ({
  url,
  bgColor,
  color,
  linkColor = 'inherit',
}: {
  url: string;
  bgColor: string;
  color: string;
  linkColor: string;
}) => {
  return (
    <QuestionSection bgColor={bgColor} color={color} linkColor={linkColor}>
      <Inner>
        <h3 className="font-u4 text-center pt-u3 pb-u3">
          Questions? Look <Link to={url}>here.</Link>
        </h3>
      </Inner>
    </QuestionSection>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box mb={4} mt={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

export const tabProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `step-${index + 1}-color`,
    style: {
      opacity: 1,
    },
  };
};
